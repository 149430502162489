<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="名称关键词" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getDirectories({ ...params, page: 1})" plain>查询</el-button>
					<el-button type="primary" @click="departure_dialog = true">添加</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<div class="dib br10 oh mr20 mb20 bsbb bcf8f6f0" style="min-width: 240px; width: 18%;" v-for="directory in directories" :key="directory.id">
					<div :style="`width: 100%; height: 180px; background-size: cover; background-repeat: no-repeat; background-position: center; background-image: url(${directory.cover});`"></div>
					<div class="p10 c3 fwb cp lh1_5" @click="edit(directory)">{{directory.name}}</div>
				</div>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getDirectories({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getDirectories({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<template slot="other">
			<el-dialog width="360px" title="创建文件夹" :visible.sync="departure_dialog" @closed="closed" append-to-body destroy-on-close>
				<el-form label-position="top" ref="departure_form" :model="directory" :rules="rules" :size="theme.size" status-icon>
					<el-form-item label="名称" prop="name">
						<el-input v-model="directory.name" placeholder="请输入名称" clearable></el-input>
					</el-form-item>
					<el-form-item label="封面" prop="cover">
						<el-upload
							class="avatar-uploader"
							:action="$api.URI_UPLOADS"
							:data="{type: departures_cfg.type}"
							:accept="departures_cfg.ext.map((e) => { return '.'+e; }).join(',')"
							:on-success="(r, f, l) => { !r.code ? (directory = {...directory, cover: r.result.url}) : $message.error(r.msg)}"
							:show-file-list="false">
							<div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
							<img v-if="directory.cover" :src="directory.cover" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('departure_form')">{{directory.id ? '提交保存' : '提交创建'}}</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</template>
	</main-table-a>
</template>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 320px;
		height: 180px;
		line-height: 180px;
		text-align: center;
	}
	.avatar {
		width: 320px;
		height: 180px;
		display: block;
		margin: 0 auto;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA,
		},
		computed: {
			...mapState(['theme', 'admin_user']),
			departures_cfg () {
				return this.$utils.uploadConfig('office_directory');
			}
		},
		filters: {
			bytesToSize(bytes) {
				const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes === 0) return '0 Byte';
				const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
				return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
			}
		},
		methods: {
			closed () {
				this.getDirectories(this.params);
			},
			edit (r) {
				this.directory = { ...r };
				this.departure_dialog = true;
			},
			async getDirectories (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_OFFICE_DIRECTORIES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.directories = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async submitDirectory (data) {
				const res = await this.$http.post(this.$api.URI_OFFICE_DIRECTORIES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getDirectories(this.params);
						this.directory = {};
						this.departure_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'departure_form':
							this.submitDirectory(this.directory);
						break;
					}
				});
			},
			async handleDirectory (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_OFFICE_DIRECTORIES, {params, headers:  { loading }});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
			}
		},
		data() {
			return {
				directories: [],
				directory: {},
				departure_dialog: false,
				params: {
					perPage: 10
				},
				rules: {
					name: [{ required: true, message: '必须填写名称', trigger: 'blur'}],
				}
			}
		},
		created () {
			this.getDirectories(this.params, true);
		}
	};
</script>